import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface OpenImageContextType {
  openImageSrc: string | null;
  openImage: (src: string) => void;
  closeImage: () => void;
}

const defaultValue: OpenImageContextType = {
  openImageSrc: null,
  openImage: () => { },
  closeImage: () => { }
};

const OpenImageContext = createContext<OpenImageContextType>(defaultValue);

export const useOpenImage = () => useContext(OpenImageContext);

interface OpenImageProviderProps {
  children: ReactNode;
}

export const OpenImageProvider: React.FC<OpenImageProviderProps> = ({ children }) => {
  const [openImageSrc, setOpenImageSrc] = useState<string | null>(null);

  const openImage = (src: string) => {
    setOpenImageSrc(src);
  };

  const closeImage = () => {
    setOpenImageSrc(null);
  };

  return (
    <OpenImageContext.Provider value={{ openImageSrc, openImage, closeImage }}>
      <div className='relative'>
        {children}
        {openImageSrc ?
          <div className='h-0'>
            <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={closeImage}></div>

            <button onClick={closeImage} className="fixed top-0 right-0 m-4 text-white z-50 flex flex-col items-center justify-center">
              <XMarkIcon className="w-10" />
              <p className="ml-3 font-semibold">Fermer</p>
            </button>

            <div className="md:hidden fixed inset-0 flex items-center justify-center z-40">
              <div className="max-w-full max-h-full m-6 overflow-auto p-4  z-50 relative">
                <img src={openImageSrc} alt="Image" className="max-w-full max-h-full relative z-10" />
              </div>
            </div>

            <div className="hidden md:inline fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 max-w-screen max-h-screen w-auto h-auto">
              <img src={openImageSrc} />
            </div>

          </div>
          : <div></div>}
      </div>
    </OpenImageContext.Provider>
  );
};