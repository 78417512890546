export const iconeCV =
<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" strokeWidth={2}>
    <path d="M3 24h19v-23h-1v22h-18v1zm17-24h-18v22h18v-22zm-1 1h-16v20h16v-20zm-2 16h-12v1h12v-1zm0-3h-12v1h12v-1zm0-3h-12v1h12v-1zm-7.348-3.863l.948.3c-.145.529-.387.922-.725 1.178-.338.257-.767.385-1.287.385-.643 0-1.171-.22-1.585-.659-.414-.439-.621-1.04-.621-1.802 0-.806.208-1.432.624-1.878.416-.446.963-.669 1.642-.669.592 0 1.073.175 1.443.525.221.207.386.505.496.892l-.968.231c-.057-.251-.177-.449-.358-.594-.182-.146-.403-.218-.663-.218-.359 0-.65.129-.874.386-.223.258-.335.675-.335 1.252 0 .613.11 1.049.331 1.308.22.26.506.39.858.39.26 0 .484-.082.671-.248.187-.165.322-.425.403-.779zm3.023 1.78l-1.731-4.842h1.06l1.226 3.584 1.186-3.584h1.037l-1.734 4.842h-1.044z" fill="#572E1E"/></svg>




export const iconeGitLab =
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.0501 15.7251L26.0252 15.6616L23.6176 9.37815C23.5686 9.255 23.4819 9.15053 23.3698 9.07973C23.2577 9.01014 23.127 8.97662 22.9952 8.9837C22.8634 8.99078 22.737 9.03813 22.633 9.11934C22.5301 9.20288 22.4555 9.31609 22.4193 9.44355L20.7936 14.4172H14.2109L12.5852 9.44355C12.55 9.31539 12.4752 9.20162 12.3715 9.11842C12.2675 9.03721 12.1411 8.98986 12.0093 8.98278C11.8776 8.9757 11.7468 9.00922 11.6347 9.07881C11.5229 9.1499 11.4363 9.25428 11.3869 9.37723L8.9747 15.6579L8.95076 15.7214C8.60417 16.627 8.56138 17.6207 8.82886 18.5528C9.09634 19.4848 9.65958 20.3046 10.4336 20.8885L10.4419 20.895L10.464 20.9107L14.1317 23.6572L15.9461 25.0305L17.0514 25.865C17.1807 25.9632 17.3386 26.0163 17.5009 26.0163C17.6632 26.0163 17.8211 25.9632 17.9504 25.865L19.0556 25.0305L20.8701 23.6572L24.5598 20.8941L24.569 20.8867C25.3414 20.3026 25.9033 19.4837 26.1704 18.5529C26.4374 17.6222 26.3952 16.6299 26.0501 15.7251Z" fill="#572E1E" />
    </svg>


export const iconeLinkedin =
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.75 8.75H12.5553V10.7004H12.6096C13.139 9.751 14.4349 8.75 16.3661 8.75C20.3825 8.75 21.125 11.2504 21.125 14.5023V21.125H17.1588V15.2537C17.1588 13.8533 17.1299 12.0521 15.0956 12.0521C13.0297 12.0521 12.7148 13.5769 12.7148 15.152V21.125H8.75V8.75Z" fill="#572E1E" />
        <path d="M1.875 8.75H6V21.125H1.875V8.75Z" fill="#572E1E" />
        <path d="M6 5.3125C6 6.45169 5.07669 7.375 3.9375 7.375C2.79831 7.375 1.875 6.45169 1.875 5.3125C1.875 4.17331 2.79831 3.25 3.9375 3.25C5.07669 3.25 6 4.17331 6 5.3125Z" fill="#572E1E" />
    </svg>


export const iconeEmail =
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.75} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
    </svg>


export const iconeBehance = 
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M22 7h-7v-2h7v2zm1.726 10c-.442 1.297-2.029 3-5.101 3-3.074 0-5.564-1.729-5.564-5.675 0-3.91 2.325-5.92 5.466-5.92 3.082 0 4.964 1.782 5.375 4.426.078.506.109 1.188.095 2.14h-8.027c.13 3.211 3.483 3.312 4.588 2.029h3.168zm-7.686-4h4.965c-.105-1.547-1.136-2.219-2.477-2.219-1.466 0-2.277.768-2.488 2.219zm-9.574 6.988h-6.466v-14.967h6.953c5.476.081 5.58 5.444 2.72 6.906 3.461 1.26 3.577 8.061-3.207 8.061zm-3.466-8.988h3.584c2.508 0 2.906-3-.312-3h-3.272v3zm3.391 3h-3.391v3.016h3.341c3.055 0 2.868-3.016.05-3.016z" fill="#572E1E"/>
</svg>



