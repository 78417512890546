import './App.css';
import Home from './components/Home/Home';
import Studies from './components/Studies/Studies';
import Experiences from './components/Experiences/Experiences';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import { useEffect } from 'react';
import AOS from 'aos';

function App() {
  useEffect(() => {
    AOS.init({
      once: true
    });
  }, []);
  
  return (
    <div className="App">
      <div className='sm:p-10'>      
        <Home />
        <Projects />
        <Experiences />
        <Studies />
        <Skills />
      </div>
    </div>
  );
}

export default App;
