import BadgeCompetence from '../Studies/components/BadgeCompetence/BadgeCompetence';
import React from 'react';

interface Experience {
  id: number;
  job: string,
  entreprise: string;
  descriptionEntreprise: string;
  contrats: {
    type: string;
    description: string;
  }[];
  competences: {
    title?: string;
    descriptions?: string[];
    badges: string[]
  }[];
  date: string;
  isStarted: boolean;
  inProgress: boolean;
}

const studies: Experience[] = [
  {
    id: 1,
    job: 'Développeur Web spécialisé Front-end',
    entreprise: 'Thera Soft',
    descriptionEntreprise: 'Entreprise spécialisée dans le développement de solutions logicielles dans le domaine de la santé',
    contrats: [
      {
        type: 'CDI',
        description: "Analyse et développement des nouveaux besoins émergeants d'une solution en production"
      },
      {
        type: 'Alternance',
        description: "Analyse et développement d'une nouvelle solution de gestion de cabinet de radiologie"
      }],
    competences: [
      {
        title: 'Compétences de gestion de projet',
        descriptions: [
          "Compétences majoritairement Front-end en partant de l'analyse et la conception de fonctionnalités pour finir par l'intégration de celle-ci. Mon profil reste néanmoins polyvalent avec d'autres tâches backend."
        ],
        badges: ['Angular', 'Figma', 'NodeJs']
      }
    ],
    date: 'Depuis mai 2020',
    isStarted: true,
    inProgress: true,
  }
];


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const Experiences: React.FC<{}> = () => (
  <section className='flex flex-col justify-center max-w-screen-lg mx-auto'>

    <React.Fragment>
      <h1 data-aos="fade" className={" text-3xl font-bold text-asBrown px-6 py-8"}>Expérience pro</h1>
      <ul data-aos="fade" className="space-y-6 p-4 mb-6">
        {studies.map((activityItem, activityItemIdx) => (
          <React.Fragment key={activityItemIdx}>
            <li className="relative flex gap-x-4">
              <div
                className={classNames(
                  '-bottom-6 absolute left-0 top-0 flex w-6 justify-center'
                )}
              >
                <div className={classNames(
                  activityItem.isStarted === true ? 'border-solid' : 'border-dashed',
                  "w-px border-black border-s"
                )} />
              </div>

              <div className="relative flex h-6 w-6 flex-none items-center justify-center ">

                {!activityItem.inProgress ?
                  <div className="h-3 w-3 rounded-full bg-asOrange ring-1 ring-asOrange" /> :
                  <div className='h-6 w-6 border-2 border-asOrange rounded-full flex items-center justify-center'>
                    <div className="h-3 w-3 rounded-full bg-asOrange ring-1 ring-asOrange" />
                  </div>}
              </div>
              <div className="flex flex-col py-0.5 text-xs leading-5 text-gray-500">
                <span data-aos="fade" className="font-bold text-asDarkOrange text-xl">{activityItem.job}</span>
                <span data-aos="fade" className='text-asBrown text-lg font-semibold'>{activityItem.entreprise}</span>
                <span data-aos="fade" className='text-asBrown text-base'>{activityItem.descriptionEntreprise}</span>


                {activityItem.competences.map((competence, competenceID) => (
                  <div data-aos="fade" key={competenceID} className='mt-4'>
                    <p className='mb-4 font-medium text-asBrown text-base'> {competence.title} </p>
                    <ul className="mb-4 list-disc list-inside text-sm marker:text-asOrange">
                      {competence.descriptions?.map((descr, descrID) => (

                        <li key={descrID} className='text-asBrown'> {descr} </li>
                      ))}
                    </ul>
                    <div className='flex flex-wrap gap-3 text-sm'>
                      {competence.badges.map((badge, badgeID) => (
                        <BadgeCompetence key={badgeID} competence={badge}></BadgeCompetence>
                      ))}
                    </div>
                  </div>
                ))}

              </div>

              <time data-aos="fade" dateTime={activityItem.date} className="absolute right-0 -mt-6 flex-none py-0.5 text-xs leading-5 text-gray-500">
                {activityItem.date}
              </time>
            </li>
            <div>
              {activityItem.contrats.map((contrat, contratId) => (
                <li key={contratId} className="relative flex gap-x-4 mb-6">
                  <div
                    className={classNames(
                      '-bottom-6 absolute left-0 top-0 flex w-6 justify-center'
                    )}
                  >
                    <div className={classNames('border-solid', "w-px border-black border-s")} />
                  </div>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center ">
                    <div className="h-2 w-2 rounded-full bg-asOrange ring-1 ring-asOrange" />
                  </div>

                  <div data-aos="fade">
                    <p className='text-asDarkOrange font-semibold text-lg'>{contrat.type}</p>
                    <p className='text-asBrown text-sm'>{contrat.description}</p>
                  </div>
                </li>
              ))}

            </div>
          </React.Fragment>
        ))}
      </ul>

    </React.Fragment>
  </section>
)

export default Experiences;