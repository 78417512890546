import { FC } from 'react';
import styles from './ErrorPage.module.css';
import { ChevronLeftIcon, FaceFrownIcon } from '@heroicons/react/20/solid';

interface ErrorPageProps {}

const ErrorPage: FC<ErrorPageProps> = () => (
  <div className={styles.ErrorPage}>
    <FaceFrownIcon className='text-asOrange w-40 mb-4'></FaceFrownIcon>
    <p className='text-5xl font-semibold text-asBrown'>404</p>
    <p className='text-asBrown mb-8'>Cette page est introuvable</p>

    <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-asOrange px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
      >
        <ChevronLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />

        <a href="/">
          Revenir à l'accueil
        </a>
      </button>
  </div>
);

export default ErrorPage;
