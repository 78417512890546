import React, { FC, ReactNode } from 'react';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

interface CardProjectProps {
  name: string;
  badgeName: string;
  description: string;
  color: string;
  codeProjet: string;
  stacks: {
    icon: ReactNode;
    name: string;
  }[],
  redirection: {
    name: string;
    link: string;
    openInNewTab: boolean;
  }
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}


const CardProject: FC<CardProjectProps> = ({ name, badgeName, description, stacks, color, codeProjet, redirection }) => (
  <div className="flex flex-col justify-between bg-asCreme shadow-md mx-4 rounded-lg p-4 md:w-full md:max-w-md transition duration-300 ease-in-out hover:shadow-xl">
    <div className='flex justify-between items-center flex-wrap'>
      <div className='flex justify-start items-center'>
        <div className={classNames(['flex justify-center items-center rounded-md h-12 w-12 mr-4 ' + color])} >{name.charAt(0)}</div>
        <p className='text-asBrown font-semibold text-xl'>{name}</p>
      </div>


      <div className='rounded-md text-sm px-4 py-1 bg-asBeige text-asDarkOrange whitespace-nowrap mt-1 '>
        {badgeName}
      </div>
    </div>

    <p className='text-gray-600 mt-3'>{description}</p>

    <div className='flex gap-3 mt-3'>
      {stacks.map((stack, key) => (
        <div key={key} className='flex items-center'>
          {stack.icon}
          <span className='text-asBrown text-sm'>{stack.name}</span>
        </div>
      ))}
    </div>

    <div className='self-end mt-5'>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-asOrange px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm transition duration-300 ease-in-out transform focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-asDarkOrange hover:translate-x-2"
      >
        <a href={redirection.link} target={redirection.openInNewTab ? '_blank' : '_self'} rel={redirection.openInNewTab ? 'noopener noreferrer' : ''}>
          { redirection.name }
        </a>
        <ChevronRightIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  </div>
);

export default CardProject;
