import { FC } from 'react';
import styles from './Home.module.css';
import AS_PRO from '../../assets/AS_PRO.jpg';
import pdf from '../../assets/CV_LAVILLE_Anne-Sophie.pdf';

import LinkButton from './components/LinkButton/LinkButton';
import { iconeBehance, iconeCV, iconeEmail, iconeGitLab, iconeLinkedin } from './icones/icones.const';

interface HomeProps {}


const Home: FC<HomeProps> = () => {
  return (
    
    <div className={styles.Home}>
      <img data-aos="fade" alt="me" src={AS_PRO} className={"w-64 h-64 object-cover rounded-full"} />
      <h1 data-aos="fade" data-aos-delay="100" className={"mt-4 text-2xl font-bold text-asBrown"}>
        Anne-Sophie LAVILLE
      </h1>
      <div data-aos="fade" data-aos-delay="200" className={'bg-asBeige rounded-full px-4 py-1 mt-2'}>
        <p className={'text-asDarkOrange font-semibold'}>Développeur Web</p>
      </div>

      <p data-aos="fade" data-aos-delay="300" className={'text-asBrown mt-8 text-center max-w-screen-lg mx-auto'}>
        Passionnée par le web et le domaine du digital, je suis <b className='text-asDarkOrange'>Développeur Web</b> et je
        souhaite approfondir et élargir mes connaissances dans le domaine du{' '}
        <b className='text-asDarkOrange'>Webdesign</b>.
      </p>

      <div data-aos="fade" data-aos-delay="400" className={'mt-8 self-end flex gap-4 max-w-screen-lg mx-auto'}>
        {/* Utilisation de Tooltip pour les boutons qui ont des tooltips */}
        <LinkButton logo={iconeEmail} link={'mailto:laville.annesophie@gmail.com'} name="Email" />
        <LinkButton logo={iconeLinkedin} link={'https://www.linkedin.com/in/anne-sophie-laville-2141991a1/'} name="Linkedin" />
        <LinkButton logo={iconeBehance} link={'https://www.behance.net/anne-solaville'} name="Behance" />
        <LinkButton logo={iconeGitLab} link={'https://gitlab.com/aslaville'} name="Gitlab" />
        <LinkButton logo={iconeCV} link={pdf} name="CV"></LinkButton>
      </div>
    </div>
  );
};

export default Home;
