import { ReactNode } from "react";
import {
    SiAntdesign,
    SiJavascript,
    SiReact,
    SiTailwindcss,
    SiNodedotjs, SiVuedotjs, SiSymfony, SiMysql,
  } from "react-icons/si";
  import { BiLogoAngular, BiLogoFigma } from 'react-icons/bi';



export interface Skill {
    name: string;
    svg: ReactNode
}

export const allSkills: Skill[] = [
    // Figma
    {
        name: 'Figma',
        svg: <BiLogoFigma className='text-5xl text-asBrown' />
    },
    // Tailwind
    {
        name: 'Tailwind',
        svg: <SiTailwindcss className='text-5xl text-asBrown' />

    },
    // UX / UI
    {
        name: 'UX / UI',
        svg: <SiAntdesign className='text-5xl text-asBrown' />

    },
    // Angular
    {
        name: 'Angular',
        svg: <BiLogoAngular className='text-5xl text-asBrown' />
    },
    // React
    {
        name: 'React',
        svg: <SiReact className='text-5xl text-asBrown' />

    },
    // Vue.JS
    {
        name: 'Vue.JS',
        svg: <SiVuedotjs className='text-5xl text-asBrown' />

    },
    // Javascript
    {
        name: 'Javascript',
        svg: <SiJavascript className='text-5xl text-asBrown' />

    },
    // MySQL
    {
        name: 'MySQL',
        svg: <SiMysql className='text-5xl text-asBrown' />

    },
    // Node.JS
    {
        name: 'Node.JS',
        svg: <SiNodedotjs className='text-5xl text-asBrown' />

    },
    // Symfony
    {
        name: 'Symfony',
        svg: <SiSymfony className='text-5xl text-asBrown' />

    },




]