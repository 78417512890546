import React, { FC } from 'react';
import styles from './Skills.module.css';
import { allSkills } from './allSkills.const';

interface SkillsProps { }

const Skills: FC<SkillsProps> = () => (
  <div className="mx-auto max-w-screen-lg pb-16">
    <h1 data-aos="fade" className={" text-3xl font-bold text-asBrown px-6 pt-8 pb-4"}>Compétences</h1>
    <p data-aos="fade" className='text-asBrown px-6 pb-8'>J'ai acquis diverses compétences au cours de mes études, de mes projets ou de mes expériences. Néanmoins, je me suis spécialisée dans le développement Front-End et j'aimerais développer des compétences autour de la communication digitale et de l'UX / UI.</p>

    <div className='px-6 flex flex-wrap items-center justify-center gap-x-10 gap-y-6'>
      {allSkills.map((skill, index) => (
        <div  key={index} className={index % 2 === 0 ? 'bg-asOrange rounded-md shadow-md transition duration-300 ease-in-out transform hover:bg-asDarkOrange hover:translate-y-2' : 'bg-asBeige rounded-md shadow-md transition duration-300 ease-in-out transform hover:translate-y-2 hover:bg-asDarkBeige'}>
          <div  data-aos="fade" className='w-24 h-24 flex flex-col justify-center items-center text-asBrown'>
            {skill.svg}

            <p className='text-sm mt-2'>{skill.name}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Skills;
