import { FC } from 'react';
import CardProject from './components/CardProject/CardProject';
import { BiLogoAngular, BiLogoFigma, BiLogoFirebase, BiLogoNodejs } from 'react-icons/bi';
import { IoLogoIonic } from "react-icons/io";
import 'aos/dist/aos.css'
import { SiFreepik } from 'react-icons/si';
import { MdDraw } from "react-icons/md";

interface ProjectsProps { }


const projects = [
  {
    name: 'MTKDC',
    codeProjet: 'MTKDC',
    badgeName: 'En cours de dev',
    description: 'Création d’un site internet pour le Myrmidons Taekwondo Club',
    stacks: [
      {
        icon: <BiLogoAngular className='text-3xl text-asBrown' />,
        name: 'Angular'
      },
      {
        icon: <BiLogoNodejs className='text-3xl text-asBrown' />,
        name: 'NodeJs'
      }
    ],
    redirection: {
      name: 'En voir plus',
      link: 'projets/MTKDC',
      openInNewTab: false
    }
  },
  {
    name: 'Cavavin',
    codeProjet: 'Cavavin',
    badgeName: 'Version Beta',
    description: "Application mobile de gestion d'un livre de cave",
    stacks: [
      {
        icon: <BiLogoAngular className='text-3xl text-asBrown' />,
        name: 'Angular'
      },
      {
        icon: <IoLogoIonic className='text-3xl text-asBrown' />,
        name: 'Ionic'
      },
      {
        icon: <BiLogoFirebase className='text-3xl text-asBrown' />,
        name: 'Firebase'
      }
    ],
    redirection: {
      name: 'En voir plus',
      link: 'projets/Cavavin',
      openInNewTab: false
    }
  },
  // {
  //   name: 'CalendarPro',
  //   codeProjet: 'CalendarPro',
  //   badgeName: 'En conception',
  //   description: "Création d’un calendrier à usage professionnel pour gérer des commandes et des rendez-vous",
  //   stacks: [] 
  // },
]

const webdesignProjects = [
  {
    name: 'Pelotte',
    codeProjet: 'Pelotte',
    badgeName: 'Branding',
    description: 'Création d\'identité et mockup pour des kits de tricot accessible et unique',
    stacks: [
      {
        icon: <BiLogoFigma className='text-3xl text-asBrown' />,
        name: 'Figma'
      },
      {
        icon: <MdDraw className='text-3xl text-asBrown' />,
        name: 'Procreate'
      },
      {
        icon: <SiFreepik className='text-3xl text-asBrown' />,
        name: 'Freepik'
      }
    ],
    redirection: {
      link: "https://www.behance.net/gallery/194106283/Pelotte-kits-de-tricot",
      name: 'Behance',
      openInNewTab: true
    }
  },
  {
    name: 'Chalet Gourmet',
    codeProjet: 'CG',
    badgeName: 'Landing page',
    description: "Création d'une landing page pour un restaurant savoyard élégant et réconfortant.",
    stacks: [
      {
        icon: <BiLogoFigma className='text-3xl text-asBrown' />,
        name: 'Figma'
      }
    ],
    redirection: {
      link: "https://www.behance.net/gallery/193533995/Chalet-Gourmet-Restaurant",
      name: 'Behance',
      openInNewTab: true
    }
  },
  // {
  //   name: 'CalendarPro',
  //   codeProjet: 'CalendarPro',
  //   badgeName: 'En conception',
  //   description: "Création d’un calendrier à usage professionnel pour gérer des commandes et des rendez-vous",
  //   stacks: [] 
  // },
]


const Projects: FC<ProjectsProps> = () => {
  return (
    <div className="mb-16 max-w-screen-lg mx-auto">
      <h1 data-aos="fade" data-aos-delay="500" className={" text-3xl font-bold text-asBrown px-6 pt-8 pb-4"}>Projets perso</h1>
      <p data-aos="fade" data-aos-delay="500" className='text-asBrown px-6 pb-8'>Réalisation de projets perso sur mon temps libre pour apprendre de nouvelles techno et créer de nouvelles choses.</p>

      <div data-aos="fade" data-aos-delay="600" className='flex flex-col gap-6 md:flex-row flex-wrap justify-center'>
        {projects.map((projet, projetId) => (
          <CardProject key={projetId} name={projet.name} badgeName={projet.badgeName} description={projet.description} stacks={projet.stacks} color={projetId % 2 === 0 ? 'bg-asOrange' : 'bg-asBeige'} codeProjet={projet.codeProjet} redirection={projet.redirection}></CardProject>
        ))}
      </div>

      <h2 data-aos="fade" data-aos-delay="600" className={" text-xl font-bold text-asDarkOrange px-6 pt-8 pb-2"}>Les challenges Webdesign !</h2>
      <p data-aos="fade" data-aos-delay="600" className='text-asBrown px-6 pb-8'>Création de branding et maquettes à partir de briefs pour me challenger et mettre en pratique toute ma créativité.</p>

      <div data-aos="fade" data-aos-delay="600" className='flex flex-col gap-6 md:flex-row flex-wrap justify-center'>
        {webdesignProjects.map((projet, projetId) => (
          <CardProject key={projetId} name={projet.name} badgeName={projet.badgeName} description={projet.description} stacks={projet.stacks} color={projetId % 2 === 0 ? 'bg-asOrange' : 'bg-asBeige'} codeProjet={projet.codeProjet} redirection={projet.redirection}></CardProject>
        ))}
      </div>
    </div>
  );
}

export default Projects;
