
import {
  PaperClipIcon,
} from '@heroicons/react/20/solid'
import BadgeCompetence from './components/BadgeCompetence/BadgeCompetence';

interface Study {
  id: number;
  title: string;
  subtitle: string;
  competences: {
    title?: string;
    descriptions?: string[];
    badges: string[]
  }[],
  date: string;
  isDone: boolean
}

const studies: Study[] = [
  {
    id: 1,
    title: 'Projet d\'intégration du master CODUX à Limoges',
    subtitle: 'Elargir mes compétences dans le web',
    competences: [
      { badges: ['UX / UI', 'Co-design', 'Webdesign', 'Communication digitale'] }
    ],
    date: 'Objectif à venir',
    isDone: false
  },
  {
    id: 2,
    title: 'LP: Développement d\'Applications Web et Big Data',
    subtitle: 'Spécialisation dans le développement web',
    competences: [
      {
        title: "Compétences professionnelles",
        descriptions: ["Réalisation d’un service de drive en ligne pour Lin’Epicerie, une épicerie située à Linards (87)"],
        badges: ['Analyse et gestion de projet', 'Alternance']
      },
      {
        title: "Compétences techniques",
        descriptions: ["Apprentissage de différents Framework web"],
        badges: ['Angular', 'VueJs', 'Symfony', 'NodeJs', 'noSQL']
      }
    ],
    date: '2021',
    isDone: true
  },
  {
    id: 3,
    title: 'DUT Informatique',
    subtitle: '',
    competences: [
      {
        title: "Compétences professionnelles",
        descriptions: ["Réalisation de nombreux projets universitaires en groupe"],
        badges: ['Analyse et gestion de projet', 'Stage en entreprise']
      },
      {
        title: "Compétences techniques",
        descriptions: ["Connaissances générales en informatique et algorithmie"],
        badges: ['HTML', 'CSS', 'Javascript', 'C++', 'Java', 'SQL', 'PHP', 'Node.Js']
      }
    ],
    date: '2018 - 2020',
    isDone: true
  }
];


function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const Studies: React.FC<{}> = () => (
  <>
      <h1 data-aos="fade" className={" text-3xl font-bold text-asBrown px-6 py-8 max-w-screen-lg mx-auto"}>Formations</h1>
      <ul data-aos="fade" className="space-y-6 p-4 pb-16 max-w-screen-lg mx-auto">
        {studies.map((activityItem, activityItemIdx) => (
          <li key={activityItemIdx} className="relative flex gap-x-4">
            <div
              className={classNames(
                '-bottom-6 absolute left-0 top-0 flex w-6 justify-center'
              )}
            >
              <div className={classNames(
                activityItem.isDone === true ? 'border-solid' : 'border-dashed',
                "w-px border-black border-s"
              )} />
            </div>
                
            <div className="relative flex h-6 w-6 flex-none items-center justify-center ">

              {activityItem.isDone ?
              <div className="h-3 w-3 rounded-full bg-asOrange ring-1 ring-asOrange" /> :
              <div className='h-6 w-6 border-2 border-asOrange rounded-full flex items-center justify-center'>
                <div className="h-3 w-3 rounded-full bg-asOrange ring-1 ring-asOrange" />
              </div>
              }
            </div>
            <div  className="flex flex-col py-0.5 text-xs leading-5 text-gray-500">
              <span data-aos="fade" className="font-bold text-asDarkOrange text-xl">{activityItem.title}</span>
              <span data-aos="fade" className='text-asBrown text-base'>{activityItem.subtitle}</span>

              {activityItem.competences.map((competence,competenceID) => (
                <div data-aos="fade" key={competenceID} className='mt-4'>
                  <p className='mb-4 font-medium text-asBrown text-base'> {competence.title} </p>
                  <ul className="mb-4 list-disc list-inside text-sm marker:text-asOrange">
                    {competence.descriptions?.map((descr, descrID) => (
                      
                      <li key={descrID} className='text-asBrown'> {descr} </li>
                    ))}
                  </ul>
                  <div className='flex flex-wrap gap-3 text-sm'>
                    {competence.badges.map((badge,badgeID) => (
                      <BadgeCompetence key={badgeID} competence={badge}></BadgeCompetence>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <time data-aos="fade" dateTime={activityItem.date} className="absolute right-0 -mt-6 flex-none py-0.5 text-xs leading-5 text-gray-500">
              {activityItem.date}
            </time>
          </li>
        ))}
      </ul>

      {/* New comment form */}
      {/* <div className="mt-6 p-4 flex gap-x-3">
        <img
          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
          alt=""
          className="h-6 w-6 flex-none rounded-full bg-gray-50"
        />
        <form action="#" className="relative flex-auto">
          <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={2}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your comment..."
              defaultValue={''}
            />
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button>
              </div>
              <div className="flex items-center">
                
              </div>
            </div>
            <button
              type="submit"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Comment
            </button>
          </div>
        </form>
      </div> */}
    </>
)

export default Studies;