import { ReactNode } from "react";
import ajoutVin from "../../assets/projets/Cavavin/Ajout.png";
import home from "../../assets/projets/Cavavin/Home.png";
import login from "../../assets/projets/Cavavin/Login.png";
import profil from "../../assets/projets/Cavavin/Profil.png";

import adminPlanning from '../../assets/projets/MTKDC/adminPlanning.png';
import adminNews from '../../assets/projets/MTKDC/newsAdmin.png';

import MTKDCHome from '../../assets/projets/MTKDC/home.png';
import MTKDCNews from '../../assets/projets/MTKDC/news.png';
import MTKDCShop from '../../assets/projets/MTKDC/shop.png';
import MTKDCHoraires from '../../assets/projets/MTKDC/horaires.png';




interface SectionProjetDetail {
    description: ReactNode;
    images: {
        id: number,
        src: string,
        alt: string,
        css: string,
    }[],
    cssGrid?: string
}
export interface DetailProjetRoute {
    code: string;
    title: string;
    subtitle: string;
    sections: SectionProjetDetail[];
}

export const allProjects: DetailProjetRoute[] = [
    {
        code: "MTKDC",
        title: 'Myrmidons Taekwondo Club',
        subtitle: 'Refonte du site internet de l\'association sportive : Myrmidons Taekwondo Club',
        sections: [
            {
                description: <div>
                    <div>
                        Club <b className="text-asDarkOrange">jeune</b> et <b className="text-asDarkOrange">dynamique</b>, le MTKDC a pour objectif de remettre son site internet à son image.
                    </div>
                    <div className="mt-4">
                        L’objectif est d’avoir un <b className="text-asDarkOrange">site vivant</b>, mis à jour régulièrement des dernières actualités du club.
                        La  création d’une section d’administration pour le site est donc indispensable pour permettre à n’importe qui d’ajouter facilement du contenu.
                    </div>
                </div>,
                images: [
                    {
                        id: 0,
                        src: adminPlanning,
                        css: '',
                        alt: 'MTKDC_admin_horaire'
                    },
                    {
                        id: 1,
                        src: adminNews,
                        css: '',
                        alt: 'MTKDC_admin_actu'
                    }
                ],
                cssGrid: 'grid grid-cols-2 gap-3 max-w-screen-xl',
            },
            {
                description: <div>
                    <div className="mt-6">
                        Le site doit permettre de trouver <b className="text-asDarkOrange">toutes les informations utiles</b> pour de nouveaux adhérents ou bien pour les personnes déjà membres du club tout en reflétant une ambiance <b className="text-asDarkOrange">familiale</b>, <b className="text-asDarkOrange">dynamique</b> et <b className="text-asDarkOrange">chaleureuse</b>.
                    </div>
                    <ul className="mt-4 list-disc list-outside marker:text-asDarkOrange">
                        <li>Une page d’accueil vivante et dynamique</li>
                        <li>Toutes les actualités du club</li>
                        <li>Les horaires et informations pratiques</li>
                        <li>Les goodies du club avec la possibilité de passer des commandes</li>
                    </ul>
                </div>,
                images: [
                    {
                        id: 0,
                        src: MTKDCHome,
                        css: '',
                        alt: 'MTKDC_home'
                    },
                    {
                        id: 2,
                        src: MTKDCHoraires,
                        css: '',
                        alt: 'MTKDC_horaire'
                    },
                    {
                        id: 1,
                        src: MTKDCNews,
                        css: '',
                        alt: 'MTKDC_news'
                    },
                    {
                        id: 3,
                        src: MTKDCShop,
                        css: '',
                        alt: 'MTKDC_shop'
                    }
                ],
                cssGrid: 'grid grid-cols-2 gap-3 max-w-xl',

            }
        ]
    },
    {
        code: "Cavavin",
        title: 'Cavavin',
        subtitle: 'Application de gestion d\'un livre de cave',
        sections: [{
            description:
                <div>
                    <div>
                        Je ne suis pas forcément très connaisseur en vin, mais mes parents le sont. Et  <b className="text-asDarkOrange">organiser une cave</b> quand on commence à avoir de la bouteille, (sans mauvais jeu de mot), c’est tout un art.
                    </div>
                    <div className="mt-6">
                        C’est pour ça que j’ai commencé à développer l’application Cavavin. Elle permet principalement d’<b className="text-asDarkOrange">enregistrer</b> et de <b className="text-asDarkOrange">visualiser</b> tout son <b className="text-asDarkOrange">inventaire de cave</b>.
                    </div>
                    <div className="mt-6">
                        L'application comporte :
                    </div>
                    <ul className="mt-4 list-disc list-outside marker:text-asDarkOrange">
                        <li>Une page d'authentification sécurisée avec la possibilité de se connecter via google ou facebook</li>
                        <li>Une liste des vins ordonnée par type où l'on peut facilement gérer sa cave en modifiant le compteur des vins</li>
                        <li>Un formulaire d'ajout de nouveau vin</li>
                    </ul>
                </div>,
            images: [
                {
                    id: 0,
                    src: login,
                    css: '',
                    alt: 'Cavavin_login'
                },
                {
                    id: 1,
                    src: ajoutVin,
                    css: '',
                    alt: 'Cavavin_ajout'
                },
                {
                    id: 2,
                    src: profil,
                    css: '',
                    alt: 'Cavavin_profil'
                },
                {
                    id: 3,
                    src: home,
                    css: '',
                    alt: 'Cavavin_home'
                }
            ],
            cssGrid: 'grid grid-cols-2 gap-3 max-w-xl',
        }],
    }
]

