import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styles from './DetailProject.module.css';
import { allProjects } from './allDetailProjects.const';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useOpenImage } from './components/OpenImage/OpenImage';
import AOS from 'aos';

interface DetailProjectProps { }


const DetailProject: FC<DetailProjectProps> = () => {
  useEffect(() => {
    AOS.init({
      once: true
    });
  }, []);

  const { projectId } = useParams<{ projectId?: string }>();

  const project = allProjects.find((p) => p.code === projectId);

  const { openImage } = useOpenImage();


  if (!project) {
    // Vous pouvez rendre un état de chargement ici si nécessaire
    return (
      <div className={styles.DetailProject}>
        <p>Il n'y a pas de référence pour le projet <b className='text-asOrange'>{projectId}</b></p>
        <button
          type="button"
          className="mt-6 inline-flex items-center gap-x-1.5 rounded-md bg-asOrange px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <ChevronLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          <a href="..">
            Retour aux projets
          </a>
        </button>
      </div>
    );
  }

  return (
    <div className={styles.DetailProject}>
            <button
        type="button"
        className="mb-6 inline-flex items-center gap-x-1.5 rounded-md bg-asOrange px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 transition duration-300 ease-in-out transform hover:-translate-x-2 hover:bg-asDarkOrange"
      >
        <ChevronLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
        <a href="..">
          Retour aux projets
        </a>
      </button>
      <h1 data-aos="fade" className='text-2xl font-semibold text-asBrown mx-auto max-w-screen-lg'>{project.title}</h1>
      <p data-aos="fade" data-aos-delay="100" className='text-asBrown text-lg mx-auto max-w-screen-lg'>{project.subtitle}</p>

      {project.sections.map((section, indexSection) => (
        <div data-aos="fade" data-aos-delay="200" key={indexSection} className='mx-auto max-w-screen-lg'>
          <div className='text-base text-asBrown mt-8'>
            {section.description}
          </div>

          <div className="flex justify-center items-center my-6">
            <div className={section.cssGrid ? section.cssGrid : ''}>
              {/* Faire map image */
                section.images.map((img) => (
                  <div key={img.id}>
                    <img className="h-auto rounded-lg cursor-pointer" src={img.src} alt={img.alt} onClick={() => openImage(img.src)}></img>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}


    </div>
  );
};

export default DetailProject;
