import React, { FC } from 'react';
import styles from './Footer.module.css';
import { LinkIcon } from '@heroicons/react/20/solid';
import CV from '../../assets/CV_LAVILLE_Anne-Sophie.pdf'

interface FooterProps { }

const Footer: FC<FooterProps> = () => (
  <div className={styles.Footer}>
    <div className='flex flex-col sm:flex-row justify-between w-full mx-auto max-w-screen-lg gap-4'>
      <div className='mb-6 sm:w-96'>
        <p className='text-asBrown font-semibold text-lg'>Anne-Sophie LAVILLE</p>
        <p className='text-asBrown text-sm'>Actuellement Développeur Web, je souhaite m'orienter vers le domaine du Webdesign.</p>

        <button className='bg-asOrange mt-3 py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:translate-x-2 hover:bg-asDarkOrange'>
          <a href={CV} className='flex justify-start items-center' target='_blank' rel="noreferrer">
            <LinkIcon className='w-6 h-6 text-white mr-2'></LinkIcon>
            <p className='text-white font-semibold'>CV</p>
          </a>
        </button>
      </div>

      <div>
        <h3 className='font-semibold text-lg text-asBrown'>Contact</h3>
        <p className='text-asBrown text-sm'>Limoges, France</p>
        <a href="mailto:laville.annesophie@gmail.com" className={styles.fancylink} target='_blank' rel="noreferrer">laville.annesophie@gmail.com</a>
      </div>
    </div>
  </div>
);

export default Footer;
