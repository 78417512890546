import { FC, ReactNode } from 'react';
import { Tooltip } from 'react-tooltip';

interface LinkButtonProps {
  logo: ReactNode,
  link: string,
  name: string
}

const LinkButton: FC<LinkButtonProps> = ({ logo, link, name }) => (
  <div data-tooltip-id={name}
    data-tooltip-content={name}
    data-tooltip-place="bottom" className={'bg-asOrange p-2 rounded-lg h-11 w-11 flex items-center justify-center transition duration-300 ease-in-out hover:bg-asDarkOrange'}>
    <a href={link} target='_blank' rel="noreferrer" className='text-asBrown'>
      {logo}
    </a>
    {/* <Tooltip id={name} /> */}
  </div>


);

export default LinkButton;
