import  { FC } from 'react';

interface BadgeCompetenceProps {
  competence: string
}

const BadgeCompetence: FC<BadgeCompetenceProps> = ({competence}) => (
  <div className='bg-asBeige inline-block py-1 px-4 rounded-full'>
    <p className='text-asDarkOrange'> { competence }</p>
  </div>
);

export default BadgeCompetence;
